@import '~bootstrap/less/bootstrap.less';
@import './fonts.less';
@import './variables.less';
@import './curtain.less';
@import './cosmo/bootswatch.less';

html,
body {
  font-size: @font-size-base;
  line-height: @line-height-base;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  flex: 0 1 auto;
}

#app {
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#create-edit-role-form {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bi-confirm-modal {
  .ant-modal-body {
    padding: 16px 32px 10px!important;
    font-family: MTSCompactRegular, sans-serif;
    font-size: 14px;
    line-height: 20px;

    button {
      border-radius: 6px;
    }
  }

  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-close-x {
    width: 32px;
    height: 32px;
    line-height: 32px;

    svg {
      fill: #000000d9;
    }
  }

  .ant-modal-close {
    border-radius: 6px;
    background-color: #FFFFFF;
    right: -48px;
  }
}
