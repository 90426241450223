.curtain {
  padding: 16px 32px !important;
  display: flex;
  flex-direction: column;
  font-size: 14px !important;
  line-height: 20px !important;

  &--mt-8 {
    margin-top: 8px !important;
  }

  &--mr-8 {
    margin-right: 8px !important;
  }

  &--border-rad-6 {
    border-radius: 6px!important;
  }

  &--mt-20 {
    margin-top: 20px !important;
  }

  &--flex-1 {
    flex-grow: 1;
  }

  &__block-title {
    font-family: MTSCompactMedium, sans-serif;
    color: #000000D9;
    margin-bottom: 20px
  }

  &__form-item {
    margin-bottom: 8px !important;
  }
}