//@import '~antd/lib/style/themes/index';
//@import '~antd/lib/style/mixins/index';
//@import '~antd/lib/style/core/base';
//
//@import '~antd/lib/style/core/iconfont';
//@import '~antd/lib/style/core/motion';
//@import '~antd/lib/style/components.less';
@import 'antd/dist/antd.css';
/*
  Theme variables here: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
*/
//@primary-color: #20a7c9;
//@info-color: #66bcfe;
//@success-color: #59c189;
//@processing-color: #66bcfe;
//@error-color: #e04355;
//@highlight-color: #e04355;
//@normal-color: #d9d9d9;
//@white: #fff;
//@black: #000;

.ant-notification {
  z-index: 9999;
}

@root-entry-name: default;